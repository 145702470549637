const breakpoints = {
// maybe we need them in future
  sm: '480px',
  //   md: '768px',
  lg: '1000px',
  xl: '1440px',
  // these breakpoint are needed just to make others work
  '2xl': '3000px',
};

export default breakpoints;
